/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./api";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getRequest = /* GraphQL */ `query GetRequest($traceId: String!) {
  getRequest(traceId: $traceId) {
    messageType
    source
    traceId
    sourceId
    flowId
    customsInventoryType
    duration
    errorCode
    errorDescription
    errorMessage
    payloadRegion
    payloadBucket
    payloadPath
    payloadSize
    payloadVersion
    timestamp
    statusTimestamp
    businessReference
    status
    inventoryLocation
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRequestQueryVariables,
  APITypes.GetRequestQuery
>;
export const getRequestDetails = /* GraphQL */ `query GetRequestDetails($traceId: String!, $logType: String!) {
  getRequestDetails(traceId: $traceId, logType: $logType) {
    items {
      hashKey
      sortKey
      timestamp
      componentName
      region
      message
      errorCode
      errorDescription
      errorMessage
      payloadRegion
      payloadBucket
      payloadPath
      payloadSize
      payloadVersion
      properties
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRequestDetailsQueryVariables,
  APITypes.GetRequestDetailsQuery
>;
export const getPayload = /* GraphQL */ `query GetPayload(
  $region: String!
  $bucket: String!
  $key: String!
  $version: String!
) {
  getPayload(region: $region, bucket: $bucket, key: $key, version: $version) {
    content
    contentType
    preSignedUrl
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPayloadQueryVariables,
  APITypes.GetPayloadQuery
>;
export const getPayloadUrlOnly = /* GraphQL */ `query GetPayloadUrlOnly(
  $region: String!
  $bucket: String!
  $key: String!
  $version: String!
) {
  getPayloadUrlOnly(
    region: $region
    bucket: $bucket
    key: $key
    version: $version
  ) {
    contentType
    preSignedUrl
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPayloadUrlOnlyQueryVariables,
  APITypes.GetPayloadUrlOnlyQuery
>;
export const listRequestsDynamicSearch = /* GraphQL */ `query ListRequestsDynamicSearch(
  $customsInventoryType: String
  $errorCode: String
  $fromTimestamp: Float
  $inventoryLocation: String
  $messageType: String
  $offsetFromCurrentTimestamp: String
  $offsetToCurrentTimestamp: String
  $sortKey: String
  $source: String
  $sourceId: String
  $status: String
  $toTimestamp: Float
  $businessReference: String
  $flowId: String
) {
  listRequestsDynamicSearch(
    customsInventoryType: $customsInventoryType
    errorCode: $errorCode
    fromTimestamp: $fromTimestamp
    inventoryLocation: $inventoryLocation
    messageType: $messageType
    offsetFromCurrentTimestamp: $offsetFromCurrentTimestamp
    offsetToCurrentTimestamp: $offsetToCurrentTimestamp
    sortKey: $sortKey
    source: $source
    sourceId: $sourceId
    status: $status
    toTimestamp: $toTimestamp
    businessReference: $businessReference
    flowId: $flowId
  ) {
    items {
      messageType
      source
      traceId
      sourceId
      flowId
      customsInventoryType
      duration
      errorCode
      errorDescription
      errorMessage
      payloadRegion
      payloadBucket
      payloadPath
      payloadSize
      payloadVersion
      timestamp
      statusTimestamp
      businessReference
      status
      inventoryLocation
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRequestsDynamicSearchQueryVariables,
  APITypes.ListRequestsDynamicSearchQuery
>;
export const listFlowsDynamicSearch = /* GraphQL */ `query ListFlowsDynamicSearch(
  $type: String
  $status: String
  $businessReference: String
  $fromTimestamp: Float
  $toTimestamp: Float
  $flowId: String
  $detail: String
  $shippingPoint: String
  $inventoryLocation: String
) {
  listFlowsDynamicSearch(
    type: $type
    status: $status
    businessReference: $businessReference
    fromTimestamp: $fromTimestamp
    toTimestamp: $toTimestamp
    flowId: $flowId
    detail: $detail
    shippingPoint: $shippingPoint
    inventoryLocation: $inventoryLocation
  ) {
    items {
      id
      status
      businessReference
      timestamp
      type
      detail
      shippingPoint
      final
      errorMessage
      inventoryLocation
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlowsDynamicSearchQueryVariables,
  APITypes.ListFlowsDynamicSearchQuery
>;
export const listFlowFieldSearch = /* GraphQL */ `query ListFlowFieldSearch($fieldName: String) {
  listFlowFieldSearch(fieldName: $fieldName) {
    items
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlowFieldSearchQueryVariables,
  APITypes.ListFlowFieldSearchQuery
>;
